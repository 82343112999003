import React from "react"
import PropTypes from "prop-types"
import CookieConsent from "react-cookie-consent"

import Header from "./header"

const Layout = ({ children, page }) => (
  <div className="container-fluid">
    <Header page={page} />
    <main>
      <div id="content-wrapper">{children}</div>
    </main>
    <footer>© {new Date().getFullYear()} - Dott. Jacopo Biraschi</footer>
    <CookieConsent
      location="bottom"
      buttonText="Accetto"
      cookieName="gatsby-gdpr-google-analytics"
      style={{ background: "#2B6777", color: "#c8d8e4" }}
      buttonStyle={{ color: "#2B6777", fontSize: "1.2rem" }}
      acceptOnScroll={true}
      acceptOnScrollPercentage={20}
    >
      Questo sito utilizza i cookies
    </CookieConsent>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
