import React from "react"
import { Link } from "gatsby"

import { useSiteMetadata } from "../hooks/useSiteMetadata"
import academia from "../../content/icons/academia.png"
import instagram from "../../content/icons/instagram.svg"
import linkedin from "../../content/icons/linkedin.svg"

const Header = ({ page = "" }) => {
  const { title } = useSiteMetadata()
  return (
    <header>
      <div id="upper-nav" className="grey-bg">
        <h1>{title}</h1>
        <div>
          <a href="mailto:jacopo.biraschi@gmail.com">
            jacopo.biraschi@gmail.com
          </a>
          <a href="tel:00393342006649">+393342006649</a>
          <span>
            <a
              href="https://www.instagram.com/jacopo.biraschi_psicologo/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="link-social" src={instagram} alt="Instagram" />
            </a>
            <a
              href="https://www.linkedin.com/in/jacopo-biraschi-a35733147/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="link-social" src={linkedin} alt="LinkedIn" />
            </a>
            <a
              href="https://independent.academia.edu/JacopoBiraschi?from_navbar=true"
              target="_blank"
              rel="noreferrer"
            >
              <img className="link-social" src={academia} alt="Academia" />
            </a>
          </span>
        </div>
      </div>
      <ul className="nav justify-content-center inverted">
        <li>
          <div className="nav-separator">|</div>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={page.length > 0 ? "/" : "#"}>
            Pagina Iniziale
          </Link>
        </li>
        <li>
          <div className="nav-separator">|</div>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={page !== "servizi" ? "/servizi" : "#"}>
            Servizi
          </Link>
        </li>
        <li>
          <div className="nav-separator">|</div>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={page !== "metodo" ? "/metodo" : "#"}>
            Il mio Metodo
          </Link>
        </li>
        <li>
          <div className="nav-separator">|</div>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={page !== "ansia" ? "/ansia" : "#"}>
            Allarme Ansia?!
          </Link>
        </li>
        <li>
          <div className="nav-separator">|</div>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={page !== "su-di-me" ? "/su-di-me" : "#"}>
            Su di me
          </Link>
        </li>
        <li>
          <div className="nav-separator">|</div>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={page !== "inizia" ? "/inizia" : "#"}>
            Inizia Ora
          </Link>
        </li>
        <li>
          <div className="nav-separator">|</div>
        </li>
      </ul>
    </header>
  )
}

export default Header
